
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import Sidebar from "@/components/left-sidebar.vue"; // @ is an alias to /src
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import HRMService from "../../../services/hrm-service";
import BuiltinService from "../../../services/buitin-service";
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import FilterService from "../../../services/filter-service";
import buitinService from "@/services/buitin-service";
import PopUp from "@/components/PopUp.vue";


@Component({
  components: {
    Header,
    Sidebar,
    Loader,
    FilterComponent,
    PopUp,

  },
})
export default class Employee extends Vue {
  loading = true;
  public filter = null;
  public filterOn = [];
  salary: any = {};
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end

  public columns: any = [
    { field: "employee__title", label: "Employee Title" },
    { field: "start_at", label: 'Start At' },
    { field: "end_at", label: 'End At' },
    { field: "basic", label: 'Basic' },
  ]

  public widths = [100, 60, 60, 60];

  public selected_expat = "all";
  public option_expat = [
    { text: "All Employees", value: "all" },
    { text: "Saudi", value: "saudi" },
    { text: "Non Saudi", value: "Non saudi" },
  ];

  public option_dept: any = [];
  public selected_dept = 0;
  public option_emptype: any = [];
  public selected_emptype = 0;
  public option_branch: any = [];
  public selected_branch = 0;
  public option_bloodgroup: any = [];
  public selected_bloodgroup = 0;
  public selected_effective_date_from = "";
  public selected_effective_date_to = "";
  import_response = "";
  public selected_items = [];
  public items = [];
  public currentPage = 1;
  get rows() {
    return this.selected_items.length;
  }

  public import_file = null;
  edit = false;

  retrieve() {
    this.loading = true;
    HRMService.getAll_salary()
      .then((response) => {
        this.items = response.data;
        this.selected_items = this.items;
        // console.log(response.data)
        this.loading = false;
        // console.log(response.data);
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }

  refreshList() {
    this.get_filters();
    this.retrieve();
  }

  expatchange() {
    if (this.selected_expat == "all") {
      this.selected_items = this.items;
    } else {
      this.selected_items = this.items.filter(
        (x) => x["expats"] == this.selected_expat
      );
    }
  }

  bloodgroupchange() {
    if (this.selected_bloodgroup == 0) {
      this.selected_items = this.items;
    } else {
      let selectedoption = this.option_bloodgroup.filter(
        (y: any) => y["value"] == this.selected_bloodgroup
      );
      this.selected_items = this.items.filter(
        (x) => x["blood_group"] == selectedoption[0]["text"]
      );
    }
  }

  effectivedateto() {
    // console.log(this.selected_effective_date_to)
    if (this.selected_effective_date_to) {
      this.selected_items = this.items.filter(
        (x) =>
          parseInt(x["employment_effective_date"]) <
          parseInt(this.selected_effective_date_to)
      );
    }
  }
  effectivedatefrom() {
    if (this.selected_effective_date_from) {
      this.selected_items = this.items.filter(
        (x) =>
          parseInt(x["employment_effective_date"]) <
          parseInt(this.selected_effective_date_from)
      );
    }
  }

  onFiltered(filteredItems: any) { }

  mounted() {
    this.retrieve();
    this.get_filters();
    this.getMapComponent();
  }
  map_fields: any = {};
  getMapComponent() {
    HRMService.getPayrollConfig().then((res) => {
      this.map_fields = res.data[0].fields_mapping;
      this.MapTable();
    });
  }

  get_filters() {
    this.loading = true;
    BuiltinService.getallbuiltin()
      .then((response) => {
        this.option_dept = [];
        let dept: any = response.data["department"];
        this.option_dept = dept.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_dept.push({ value: 0, text: "Please select an option" });

        this.option_bloodgroup = [];
        let bloodgroup = response.data["blood_group"];
        this.option_bloodgroup = bloodgroup.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_bloodgroup.push({
          value: 0,
          text: "Please select an option",
        });

        this.option_emptype = [];
        let employment_type = response.data["employment_type"];
        this.option_emptype = employment_type.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_emptype.push({ value: 0, text: "Please select an option" });

        this.option_branch = [];
        let branch = response.data["branch"];
        this.option_branch = branch.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_branch.push({ value: 0, text: "Please select an option" });
        this.loading = false;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  submit_import() {
    const isValidFile = this.validateFile(this.import_file);
    if(!isValidFile){
      this.showModal("Invalid file. Please upload a valid file by download the sample.",[],"error")
    }else {
    this.import_response = "Please wait, it will take time.";
    this.loading = true;
    this.import_response = "";
    if (this.import_file != null) {
      HRMService.post_salary_file(this.import_file)
        .then((response) => {
          this.import_response = "Uploaded.";
          this.loading = false;
          this.retrieve()
        })
        .catch((e) => {
          if (e.response.data.message) {
            // console.log(e.response.data)
            this.import_response = e.response.data.message;
            this.showModal("Not Uploaded, Some Following Are The Errors.\n Please Check Below The Import Button", [], 'error')
            this.loading = false;
          }
          else {
            this.import_response = "Error occured while uploading.";
            this.loading = false;
            console.log(e);
            this.showModal(e.response.data, [], 'error')
          }
        });
    } else {
      this.import_response = "Please upload the file.";
      this.loading = false;
    }
  }
  }

  onRowClicked(item: any, index: any, event: any) {

  }
  filter_fields: any = [
    {
      type: "number",
      label: "Basic",
      name: "basic",
      value: "",
      param_name: "basic",
      filter_type: "simple",
    },
  ];
  advance_filter_fields = [
    {
      type: "range",
      label: "Basic",
      min_param_name: "start_salary_basic",
      max_param_name: "end_salary_basic",
      value_2: [0, 50000],
      min: 0,
      max: 100000,
      filter_type: "advance",
    },
  ];
  handleFilterChange(element: any) {
    if (element.filter_type === "simple") {
      interface SimpleObj {
        [key: string]: any;
      }
      const simpleFilteredObj: SimpleObj = {};
      this.filter_fields.forEach((filter: any) => {
        simpleFilteredObj[filter.param_name] = filter.value;
      });
      this.loading = true;
      FilterService.getAll(simpleFilteredObj).then((data) => {
        if (
          data.data.employee_salary !== undefined &&
          data.data.employee_salary !== null
        ) {
          this.selected_items = data.data.employee_salary;
          this.loading = false;
        } else {
          this.retrieve();
        }
      });
    } else if (element.filter_type === "advance") {
      interface AdvanceObj {
        [key: string]: any;
      }
      const advanceFilteredObj: AdvanceObj = {};
      this.advance_filter_fields.forEach((filter) => {
        if (filter.type === "range") {
          if (filter.min_param_name && filter.value_2) {
            advanceFilteredObj[filter.min_param_name] =
              filter.value_2[0] === 0 ? "" : filter.value_2[0];
          }
          if (filter.max_param_name && filter.value_2) {
            advanceFilteredObj[filter.max_param_name] =
              filter.value_2[1] === 0 ? "" : filter.value_2[1];
          }
        }
      });
      this.loading = true;
      FilterService.getAll(advanceFilteredObj).then((data) => {
        if (
          data.data.employee_salary !== undefined &&
          data.data.employee_salary !== null
        ) {
          this.selected_items = data.data.employee_salary;
          this.loading = false;
        } else {
          this.retrieve();
        }
      });
    }
  }
  updateFilterFields(fields: any[]) {
    this.filter_fields = fields;
  }
  refreshDateFeild(element: any) {
    // this.retrieve();
    element.range = [];
  }
  refreshFilters() {
    for (let field of this.filter_fields) {
      field.value = "";
    }
    this.retrieve();
  }
  refreshAdvanceFields() {
    for (let field of this.advance_filter_fields) {
      if (field.type == "range") {
        field.value_2 = [0, 50000];
      }
      this.retrieve();
    }
  }
  public salaryfields: any = [
    { key: "id", label: "Sno", visible: true },
    { key: "employee__title", label: "Employee Title", visible: true },
    { key: "start_at", visible: true },
    { key: "end_at", visible: true },
    { key: "basic", visible: true },
  ];

  MapTable() {
    for (const key in this.map_fields) {
      if (
        key.startsWith("monthly_addition") ||
        key.startsWith("monthly_deduction")
      ) {
        const fieldData = this.map_fields[key];
        if (fieldData.status === true || fieldData.status === "true") {
          let newField = {
            key: key,
            label: fieldData.name,
            sortable: true,
            visible: true,
          };
          let colFeild = {
            field: key,
            label: fieldData.name,
          }
          let simplefilterFeilds = {
            type: "number",
            label: fieldData.name,
            name: fieldData.name,
            value: "",
            param_name: key,
            filter_type: "simple",
          };
          let advancefilterFeilds = {
            type: "range",
            label: fieldData.name,
            min_param_name: `start_${key}`,
            max_param_name: `end_${key}`,
            value_2: [0, 50000],
            min: 0,
            max: 100000,
            filter_type: "advance",
          };
          this.salaryfields.push(newField);
          this.columns.push(colFeild)
          this.widths.push(60)
          this.filter_fields.push(simplefilterFeilds);
          this.advance_filter_fields.push(advancefilterFeilds);
        }
      }
    }
    this.filter_fields.push({
      type: "number",
      label: "Total",
      name: "total",
      value: "",
      param_name: "total",
      filter_type: "simple",
    });

    this.advance_filter_fields.push({
      type: "range",
      label: "Total",
      min_param_name: "start_salary_total",
      max_param_name: "end_salary_total",
      value_2: [0, 50000],
      min: 0,
      max: 100000,
      filter_type: "advance",
    });
    this.salaryfields.push(
      { key: "total", visible: true },
      { key: "comments", visible: true }
    );
    this.columns.push({ field: "total", label: "Total" },)
    this.widths.push(60)
  }
  get visibleFields() {
    return this.salaryfields.filter((field: any) => field.visible);
  }
  downloadSample() {
    const payload: any = {
      type: "bulk_import_salary_document",
    };
    BuiltinService.getSheetSamples(payload).then((response) => {
      if (response.status === 200) {
        if (response.data.result[0].builtin_document !== undefined) {
          const anchor = document.createElement("a");
          const fileUrl = process.env.VUE_APP_SERVER + response.data.result[0].builtin_document;
          anchor.href = fileUrl
          anchor.download = "/bulk_import_salary_document";
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        }
        this.showModal('File Has Been Downloaded', [], "success");
      }
      else if (response.status === 204) {
        this.showModal(response?.data?.result, [], "error");
      }
    }).catch((err) => {
      if (err?.response?.data?.result) {
        this.showModal(err?.response?.data?.result, [], "error");
      }
      else {
        this.showModal('Something Went Wrong', [], "error");
      }
    })
  }
  resetFile() {
    this.import_file = null;
    this.import_response = "";
  }
  validateFile(file:any) {
      // Check if a file is selected
      if (!file) {
        return null;
      }
      // Get the file name
      const fileName = file.name;

      // Define allowed file extensions (PDF and Excel)
      const allowedExtensions = ['.xls', '.xlsx','.ods','.xlsm,','.xlsb','.xltx','.xltm','.xlam'];
      // Check if the file has an allowed extension
      const isValid = allowedExtensions.some(extension => fileName.toLowerCase().endsWith(extension));
      return isValid ? true : false;
    }
}
